<template>
  <div style="background: #f6f6f6;color: #000;">
    <van-nav-bar title="保险统计" left-arrow :fixed="true" @click-left="back" :z-index="999">
    </van-nav-bar>
    <div class="nav-bar">

      <div class="flsx">
        <van-icon @click="timeshang" name="play" style="transform:rotate(180deg);" />
        <span @click="setstiem">{{ stime }} ~ {{ etime }}</span>

        <van-icon @click="timexia" name="play" />
      </div>
      <van-row class="hedt">
        <van-col span="6">序号</van-col>
        <van-col span="6">保险名称</van-col>
        <van-col span="6" style="padding-right: 5px;">工单数</van-col>
        <van-col span="6">金额</van-col>
      </van-row>
    </div>


    <div class="pt">
      <div class="mian" v-for="(item, idx) in list" :key="idx">
        <van-row class="mian_row">
          <van-col span="6">{{ idx + 1 }}</van-col>
          <van-col span="6" >{{ item.name }}</van-col>
          <van-col span="6" style="padding-right: 5px;">{{ item.counts }}</van-col>
          <van-col span="6">{{ item.amount }} </van-col>
        </van-row>
      </div>
    </div>
    <van-row class="mian_row boots">
      <van-col span="6">合计</van-col>
      <van-col span="6"></van-col>
      <van-col span="6" style="padding-right: 5px;">{{ counts }}</van-col>
      <van-col span="6">{{amount }} </van-col>
    </van-row>
    <van-popup :close-on-click-overlay="false" v-model="timeshow" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker @cancel="timeshow = false" @confirm="settiem" v-model="currentDate" type="year-month"
        :title="timename" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
    <van-empty v-if="list.length < 1" description="暂无数据~" />
  </div>
</template>
  
<script>
import { insurance } from "@/api/check";
import moment from "moment";
export default {
  data() {
    return {
      list: [], //
      bform: {},
      numForm: {},
      gid: this.$route.query.gid,
      timeshow: false,
      xqlist: [],
      dform: {
        carModel: '',
        source: '',
        sellsMan: '',
        counts: '',
      },
      currentDate: new Date(),
      key: "",
      stime: moment().format("YYYY-MM"),
      etime: moment().format("YYYY-MM"),
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2030, 10, 1),
      timename: '选择起始年月',
      acv: 0,
      yqlist: [],
      page: 0,
      price: 0,
      size: 15,
      counts: 0,
      amount: 0,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
    };
  },
  methods: {

    setstiem() {
      this.timename = '选择起始年月'
      this.timeshow = true
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    settiem(e) {
      if (this.timename == '选择起始年月') {
        this.stime = moment(e).format("YYYY-MM");
        this.timeshow = false

        setTimeout(() => {
          this.timename = '选择截止年月'
          this.timeshow = true
        }, 500);

      } else {
        this.etime = moment(e).format("YYYY-MM");
        this.page = 0;
        this.total = 0;
        this.list = [];
        this.getlist();
        this.timeshow = false;
      }




    },


    timeshang() {
      this.page = 0;
      this.total = 0;
      this.list = [];
      this.stime = moment(this.stime)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM");
      this.etime = this.stime
      this.getlist();
    },
    timexia() {
      this.page = 0;
      this.list = [];
      this.total = 0;
      this.etime = moment(this.etime)
        .startOf("month")
        .subtract("months", -1)
        .format("YYYY-MM");
      this.stime = this.etime
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    setacv(type) {
      this.getlist();
    },


    getlist() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      var data = {
        gid: this.gid,
        stime: this.stime,
        eitme: this.etime,
      };
      insurance(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          this.list = e.data;
          var p1 = 0;
          var p2 = 0;
          for(var i in this.list){
            p1+= this.list[i].counts
            p2+= this.list[i].amount
          }
          this.counts= p1;
          this.amount = p2
        }
      });
    },
    back() {
      // window.androidjs.onCallbackIntent("MainActivity");
      this.$router.go(-1);
    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    //
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (this.isiOS) {
        this.isok = false;
        this.setupWebViewJavascriptBridge((bridge) => {
          var token = bridge.callHandler(
            "getToken",
            null,
            function responseCallback(responseData) {
              localStorage.setItem("Authorization", responseData);
              _this.token = responseData;
              // alert('走了这里' +  _this.token)
              _this.getlist();

              return responseData;
            }
          );
        });
        console.log("ios");
      } else {
        this.isok = true;
        _this.token = window.androidjs.getLocalData("getToken");
        localStorage.setItem("Authorization", _this.token);
        // alert(_this.token)
        _this.getlist();
        console.log("andriod");
      }
    },
  },
  created() {
    // this.getlist();
    //判断是否在微信环境

    this.appSource();

  },
  mounted() { },
  computed: {},
};
</script>
<style>
body {
  background: #f6f6f6;
}
</style>
<style lang="less" scoped>
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.fsdd {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;

  .van-search {
    flex: 1;
  }
}

.lanse {
  color: #d80d0d;
  font-weight: bold;
  font-size: 16px;
  padding: 0 2px;
}

.boots {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.flsx {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 40px;
}

.flsxsss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 40px;
  padding: 0 12px;
}

.topss {
  line-height: 35px;
  font-size: 17px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  color: #666;
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  // line-height: 35px;

  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 5px 10px;
  background: #fff;

  // padding-top: 46px;
  .sgdiv {}

  .ts {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #666;
    transform: scale(0.9)
  }
}

.flx {
  display: flex;
  align-items: center;
}

.flxtop {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid #f5f5f5;

  .f1 {
    flex: 1;

    .bjs {
      background-color: #f5f5f5;
      display: inline-block;
      padding: 0 2px;
      border-radius: 2px;
      line-height: 18px;
    }

    .jinr {
      font-size: 14px;
    }
  }
}

.pt {
  padding-top: 46px;
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}

.mian_row {
  // line-height: 35px;

  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  background: #fff;
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}

.ding {
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 45px;
  border-top: 1px solid #dcdcdc;
}
</style>

  
  